import { keysSelector } from 'Modules/converter/index.js';

export default function makeTemplateApplicator(widget) {
  const mapValue = typeMap[widget.type] || {}; // eslint-disable-line @typescript-eslint/no-use-before-define
  const Component = widget.component;
  let config = { getProps: () => ({}) };

  if (typeof mapValue === 'object') {
    config = {
      ...config,
      ...mapValue,
    };
  }

  const { getProps } = config;

  return Component
    ? (template, { widgetName, items }) => {
        const props = { template, key: widgetName, items, ...getProps(widget) };
        return <Component {...props} />;
      }
    : null;
}

const facetPanelKeys = [
  'fields',
  'name',
  'ignoreFields',
  'showAllAlways',
  'sortEntries',
  'disableCollapse',
  'initCollapsed',
  'initExpandedFacets',
  'valuesCountToShowFilterInput',
];

const searchBoxKeys = ['name', 'onDropdownItemsReceived', 'onSubmit', 'disableDropdown', 'redirectUrl'];

const vehicleWidgetKeys = [
  'name',
  'doNotRedirectOnVehicleSelect',
  'initCollapsed',
  'isAlwaysColumnLayout',
  'isAutoVehicleSelectionDisabled',
  'fields',
  'globalVehicleDiscardEnabled',
  'columnBreakpoint',
  'facetToggleConfig',
  'redirectUrl',
  'useNativeDropdown',
  'useSearchableDropdown',
];
const vehicleWidgetKeysSelector = keysSelector(...vehicleWidgetKeys);
const vehicleWidgetTabsKeysSelector = keysSelector(...vehicleWidgetKeys, 'tabs');

const typeMap = {
  DialogButton: {
    getProps: keysSelector('dialogName', 'onClick', 'metadata'),
  },
  FacetPanel: {
    getProps: keysSelector(...facetPanelKeys),
  },
  FacetBar: {
    getProps: keysSelector('fields', 'initCollapsed', 'useNativeDropdown', 'minFacetCount'),
  },
  FilterChips: {
    getProps: keysSelector('fields', 'ignoreFields'),
  },
  FitmentTable: {
    getProps: keysSelector('initCollapsed', 'columnBreakpoint'),
  },
  Garage: {
    getProps: keysSelector(
      'name',
      'doNotRedirectOnVehicleSelect',
      'isAutoVehicleSelectionDisabled',
      'columnBreakpoint',
      'redirectUrl',
      'useNativeDropdown',
      'useVehicleWidget',
    ),
  },
  RequestPanel: {
    getProps: keysSelector(
      'isAutoRedirectDisabled',
      'initCollapsed',
      'inputFields',
      'onSubmit',
      'requiredFields',
      'redirectUrl',
      'requestExtra',
      'selectFields',
      'shouldBeIsolated',
      'useNativeDropdown',
    ),
  },
  ProductData: {
    getProps: keysSelector('fields'),
  },
  RelatedItems: {
    getProps: keysSelector(
      'name',
      'count',
      'getSelection',
      'onItemsRendered',
      'requestExtra',
      'sort',
      'useManualItems',
      'useRecEngine',
    ),
  },
  SearchBox: {
    getProps: keysSelector(...searchBoxKeys),
  },
  SearchBoxDialogButton: {
    getProps: keysSelector(...searchBoxKeys),
  },
  SearchHeader: {
    getProps: keysSelector(
      'facetToggleConfig',
      'pageSizes',
      'pageSizeSelectClass',
      'doNotHideOnNoResults',
      'sortEntries',
      'sortSelectClass',
    ),
  },
  SearchResult: {
    getProps: keysSelector('instantInfiniteScroll', 'infiniteScrollDisabled', 'updateCallback'),
  },
  VehicleWidget: {
    getProps: (widget) => {
      const [nameRoot] = widget.name.split('_');
      return {
        ...vehicleWidgetKeysSelector(widget),
        isAlwaysActive:
          widget.isAlwaysActive ??
          (nameRoot === 'DialogVehicleWidget' ||
            nameRoot === 'HeaderVehicleWidget' ||
            nameRoot === 'HomeVehicleWidget' ||
            nameRoot === 'VehicleSpecs'),
      };
    },
  },
  VehicleTabs: {
    getProps: (widget) => ({
      ...vehicleWidgetTabsKeysSelector(widget),
    }),
  },
  VehicleTiles: {
    getProps: keysSelector('name'),
  },
  VerifyFitment: {
    getProps: keysSelector(
      'name',
      'initCollapsed',
      'isAlwaysColumnLayout',
      'isAutoVehicleSelectionDisabled',
      'globalVehicleDiscardEnabled',
      'columnBreakpoint',
      'useNativeDropdown',
      'useSearchableDropdown',
    ),
  },
  FacetTiles: {
    getProps: keysSelector(
      'columnBreakpoint',
      'excludeFieldPreselection',
      'facetField',
      'initCollapsed',
      'isAlwaysActive',
      'isMultiLevel',
      'isVehicleWidgetDisabled',
      'view',
      'useNativeDropdown',
    ),
  },
  SearchBoxDialog: {
    getProps: keysSelector(...searchBoxKeys),
  },
  FacetDialog: {
    getProps: keysSelector(...facetPanelKeys),
  },
  SimpleColorPaletteDialog: {
    getProps: keysSelector('field'),
  },
  ColorExtractorDialog: {
    getProps: keysSelector('field'),
  },
  VehicleInfo: {
    getProps: keysSelector('initCollapsed', 'columnBreakpoint'),
  },
  WheelTireVehicleInfo: {
    getProps: keysSelector('initCollapsed', 'autoSyncVisualization'),
  },
  AutoSyncVisualization: {
    getProps: keysSelector('apiKey', 'config', 'classModificator'),
  },
  AutoSyncVisualizationDialog: {
    getProps: keysSelector('apiKey', 'config'),
  },
};
