import pageType from 'Addons/search/pageType.ts';
import uiConfig from 'Models/uiConfig/uiConfig.js';
import { bigcommerceAppClientId } from 'Stores/_common/constants.js';

const customer = getCustomerAsync();

export default {
  getLocalPreselection,
  getCustomerAsync: () => customer,
  getSearchRequestDefaults,
  facetResponseHandler,
  responseHandler,
};

function getLocalPreselection() {
  return null;
}

getLocalPreselection.defaults = { getter: getLocalPreselection };

async function getCustomerAsync() {
  const options = {
    method: 'GET',
    headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
  };

  return fetch(`/customer/current.jwt?app_client_id=${bigcommerceAppClientId}`, options)
    .then((response) => {
      if (response.ok && !response.redirected) {
        return response.json();
      }
      return Promise.reject(response);
    })
    .then((response) => {
      const [, jsonPart] = response.token.split('.');
      const { customer } = JSON.parse(atob(jsonPart));
      if (customer) {
        window.Convermax.customerGroupId = customer.group_id;
        window.Convermax.customerId = customer.id;
        window.Convermax.isLoggedIn = !!customer;
        return customer;
      }
      window.Convermax.isLoggedIn = false;
      return null;
    })
    .catch((err) => {
      if (err.status !== 404) {
        console.error(err);
        window.Convermax.isLoggedIn = null;
      } else {
        window.Convermax.isLoggedIn = false;
      }
      return null;
    });
}

function getSearchRequestDefaults(pageType) {
  const requestDefaults = {};

  if (
    window.location.pathname.split('/').filter(Boolean).length >= 1 &&
    (pageType === 'category' || pageType === 'brand')
  ) {
    requestDefaults.referrer = window.location.pathname;
  }

  return requestDefaults;
}

function facetResponseHandler(response) {
  const { preselectedCategoryTerm } = response.Extra;

  if (uiConfig.pageType === pageType.category && response.Facets && preselectedCategoryTerm) {
    const categoryFacet = response.Facets.find((facet) => facet.FieldName === 'category');

    if (categoryFacet) {
      const categoryPreselection = categoryFacet.Selection?.find(
        (facet) => facet.Term === preselectedCategoryTerm,
      )?.Term;

      if (categoryPreselection) {
        categoryFacet.TreeLevel = categoryPreselection.split('>').length - 1;
      }
    }
  }

  return response;
}

function responseHandler(response) {
  return facetResponseHandler(response);
}
