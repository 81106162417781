//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-404:_7796,_1412,_3260,_7044,_8296,_9395,_3772,_7292;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-404')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-404', "_7796,_1412,_3260,_7044,_8296,_9395,_3772,_7292");
        }
      }catch (ex) {
        console.error(ex);
      }