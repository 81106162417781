import pageType from 'Addons/search/pageType.ts';
import { setFitmentSearchResponseFacets } from 'Core/actions/fitmentSearch/index.js';
import { setResponse } from 'Core/actions/response.ts';
import { tryResponseRedirect } from 'Core/epics/common.js';
import { searchPreselectionSelector } from 'Core/selectors/preselection.js';
import fitmentSearchConfig from 'Models/uiConfig/fitmentSearchConfig.js';
import uiConfig from 'Models/uiConfig/uiConfig.js';
import { abortControllerCreator, search } from 'Modules/serverApi/index.js';

const createAbortController = abortControllerCreator();

export default function sendRequest(arg, request) {
  const {
    state,
    dispatch,
    action: { onInit, meta: { extra = {}, responseMeta = {} } = {} },
  } = arg;
  const preselection = searchPreselectionSelector(state);
  const selection = [...request.selection, ...preselection];

  const isYmmMode =
    (responseMeta.isVehicleSelectionIsolated ?? fitmentSearchConfig.isVehicleSelectionIsolated) &&
    extra.vehicleValueJustReplaced;

  const req = { ...request, selection, extra };
  if (isYmmMode) {
    req.pageSize = '0';
    req.mode = 'YMM';
  }

  search(req, createAbortController())
    .then((response) => {
      if (
        response.State === 'cancelled' ||
        (uiConfig.pageType === pageType.search && tryResponseRedirect(arg, response))
      ) {
        return;
      }

      if (isYmmMode) {
        dispatch(
          setFitmentSearchResponseFacets(
            response.Facets,
            responseMeta.isAutoVehicleSelectionDisabled,
            responseMeta.isPartialMode,
          ),
        );
      } else {
        dispatch(
          setResponse(response, {
            appendItems: request.action === 'add',
            preselection,
            onInit,
            ...responseMeta,
          }),
        );
      }
    })
    .catch(console.warn);
}
