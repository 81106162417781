import requestConfig from 'Models/uiConfig/requestConfig.js';
import { convertibleFromObject } from 'Modules/converter/index.js';
import { facetsToConvertible } from './common/convert.js';
import { GET, getErrorSearchResponse } from './common/httpClient.js';

export default async function facets(facets, extra = {}) {
  const requestDefaults = { ...requestConfig.requestDefaults };
  const requestExtra = { ...requestDefaults.extra, ...extra };

  try {
    return await GET('facets.json', {
      facet: facetsToConvertible({ selection: facets }),
      extra: await convertibleFromObject(requestExtra),
      referrer: requestDefaults.referrer ?? '',
    });
  } catch (err) {
    console.warn(err || 'Facets request failed');
    const message = await getErrorSearchResponse(err);
    return message;
  }
}
