import { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isolatedVerifyFitment } from 'Addons/fitmentSearch/isolatedKeys.ts';
import { dialogOpened } from 'Core/actions/dialog.ts';
import {
  createVerifyFitmentSpecifyFacets,
  isVehicleSelectedSelector,
  createIsServerChangedVehicleSelector,
  createFilteredFitmentSearchResponseFieldsSelector,
  createRequiredFitmentSearchFieldsSelector,
  createFitmentSearchResponseSelectionSelector,
  selectedVehicleSelector,
} from 'Core/selectors/fitmentSearch/index.js';
import { verifyFitmentProductDataSelector } from 'Core/selectors/product.ts';
import { FacetValueFull, Vehicle } from 'Models';
import { getClassesByLayoutState, useLayoutState } from '../vehicleWidget/useLayoutState.tsx';
import VerifyFitmentDropdowns from './verifyFitmentDropdowns.tsx';
import VerifyFitmentLabel from './verifyFitmentLabel.tsx';
import VerifyFitmentSpecifier from './verifyFitmentSpecifier.tsx';

import type { TemplateFunction } from 'Components/types.ts';
import type { FacetCollection } from 'Models';
import type { Params as VehicleDropdownsParams } from './verifyFitmentDropdowns.tsx';
import type { Params as VehicleLabelParams } from './verifyFitmentLabel.tsx';
import type { Params as VehicleSpecifierParams } from './verifyFitmentSpecifier.tsx';
import type { VehicleValue } from 'Models/vehicle.ts';

const isolatedKey = isolatedVerifyFitment;

type Params = VehicleDropdownsParams | VehicleLabelParams | VehicleSpecifierParams;

interface Props {
  template: TemplateFunction<Params>;
  name: string;
  isAlwaysColumnLayout: boolean;
  columnBreakpoint?: number;
  initCollapsed: boolean;
  isAutoVehicleSelectionDisabled?: boolean;
  globalVehicleDiscardEnabled?: boolean;
  useNativeDropdown?: boolean;
  useSearchableDropdown?: boolean;
  onSelectVehicle?: () => void;
}

const VerifyFitment: FC<Props> = ({
  template,
  isAlwaysColumnLayout = false,
  columnBreakpoint,
  name,
  initCollapsed,
  isAutoVehicleSelectionDisabled,
  globalVehicleDiscardEnabled,
  useNativeDropdown,
  useSearchableDropdown,
  onSelectVehicle: onSelectVehicleProp,
}) => {
  const rootRef = useRef<HTMLElement>(null);
  const dispatch = useDispatch();

  const [aboutToChange, setAboutToChange] = useState(false);

  const responseFields = useSelector(
    createFilteredFitmentSearchResponseFieldsSelector(isolatedKey),
  ) as string[];
  const requiredFields = useSelector(createRequiredFitmentSearchFieldsSelector(isolatedKey)) as
    | string[]
    | null;
  const selection = useSelector(
    createFitmentSearchResponseSelectionSelector(isolatedKey),
  ) as FacetValueFull[];

  // if a user sets a vehicle without some optional fields, we want to set those fields as ignored
  // and not to show the dialog to specify them
  const responseVehicle = new Vehicle(
    responseFields
      .map(
        (field) =>
          selection.find((v) => v.field === field) ||
          (!requiredFields?.includes?.(field) && { field, term: '__ignored', value: '__ignored' }),
      )
      .filter(Boolean) as VehicleValue[],
    responseFields,
  );

  const selectedVehicle = useSelector(selectedVehicleSelector);
  const isVehicleSelected = useSelector(isVehicleSelectedSelector);
  const isVehicleChangedOnResponse = useSelector(createIsServerChangedVehicleSelector(isolatedKey));

  const facetsToSpecify = useSelector(createVerifyFitmentSpecifyFacets(isolatedKey)) as FacetCollection;

  const verifyFitmentProductData = useSelector(verifyFitmentProductDataSelector);
  const fitsTheVehicle = verifyFitmentProductData?.fitsTheVehicle;

  const layoutState = useLayoutState(rootRef, isolatedKey, columnBreakpoint, isAlwaysColumnLayout);
  const labelTemplate = getLabelTemplateByFits(fitsTheVehicle);

  useEffect(
    function showLabelWhenVehicleChanges() {
      setAboutToChange(false);
    },
    [selectedVehicle],
  );

  if (
    !verifyFitmentProductData ||
    !Object.keys(verifyFitmentProductData).length ||
    fitsTheVehicle === 'nonVehicleProducts'
  ) {
    return null;
  }

  const onChangeVehicle = () => setAboutToChange(true);
  const onSelectVehicle = () => {
    onSelectVehicleProp?.();
    setAboutToChange(false);
  };
  const openDialog = () => dispatch(dialogOpened('vehicle-dialog', { withCurrentVehicle: true }));

  const appendedClasses = [...getClassesByLayoutState(layoutState)];

  if (!aboutToChange && isVehicleSelected && facetsToSpecify.length) {
    const props = {
      template,
      rootRef,
      appendedClasses,
      isolatedKey,
      onSelectVehicle,
      facetsToSpecify,
      vehicleString: responseVehicle.toString(),
    };
    return <VerifyFitmentSpecifier {...props} />;
  }

  const isLockedTemplate =
    !aboutToChange && isVehicleSelected && !isVehicleChangedOnResponse && !facetsToSpecify.length;

  if (labelTemplate || isLockedTemplate) {
    const labelProps = {
      template,
      rootRef,
      templateName: labelTemplate || 'locked',
      appendedClasses,
      isolatedKey,
      globalVehicleDiscardEnabled,
      verifyFitmentProductData,
      onChangeVehicle,
      openDialog,
    };

    return <VerifyFitmentLabel {...labelProps} />;
  }

  const dropdownsProps = {
    template,
    rootRef,
    appendedClasses,
    isolatedKey,
    name,
    layoutState,
    responseVehicle,
    aboutToChange,
    globalVehicleDiscardEnabled,
    initCollapsed,
    isAutoVehicleSelectionDisabled,
    useNativeDropdown,
    useSearchableDropdown,
    onSelectVehicle,
    openDialog,
  };
  return <VerifyFitmentDropdowns {...dropdownsProps} />;
};

function getLabelTemplateByFits(fits) {
  switch (fits) {
    case 'universal':
    case 'unknown':
      return fits;
    default:
      return null;
  }
}

export default VerifyFitment;
