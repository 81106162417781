import { useDispatch, useSelector } from 'react-redux';

import { createSelectRepeater, SelectContextProvider } from 'Components/smallComponents/select.tsx';
import { replaceVehicleValue } from 'Core/actions/fitmentSearch/request.ts';
import { isolatedDiscardVehicle, vehicleSelected } from 'Core/actions/fitmentSearch/vehicle.ts';
import {
  createAllRequiredFieldsHaveSelectionSelector,
  createFitmentSearchResponseFacetsSelector,
  createFitmentSearchResponseSelectionSelector,
  fitmentSearchFieldsSelector,
  isVehicleSelectedSelector,
} from 'Core/selectors/fitmentSearch/index.js';
import { Vehicle } from 'Models/index.ts';
import fitmentSearchConfig from 'Models/uiConfig/fitmentSearchConfig.js';
import { cloneSafe } from 'Utils/components.ts';
import { useCollapsing } from '../vehicleWidget/useCollapsing.tsx';

import type { RefObject } from 'react';
import type { Params as SelectParams } from 'Components/smallComponents/select.tsx';
import type { IsolatedKey } from 'Addons/fitmentSearch/isolatedKeys.ts';
import type { RepeaterFunctionInvoker, TemplateFunction } from 'Components/types.ts';
import type { FacetCollection, FacetValueFull } from 'Models/index.ts';
import type { CloneSafeOptionsType } from 'Utils/components.ts';
import type { LayoutType } from '../vehicleWidget/useLayoutState.tsx';

export type Params = {
  name: string;
  template: string;
  selects: RepeaterFunctionInvoker<SelectParams>;
  fitmentSearchTitle: string;
  aboutToChange: boolean;
  allowToDiscardVehicle: boolean;
  allowToSetVehicle: boolean;
  vehicleString: string;
  discardVehicle: () => void;
  openDialog: () => void;
  selectVehicle: () => void;
} & ReturnType<typeof useCollapsing>;

type Props = {
  template: TemplateFunction<Params>;
  rootRef: RefObject<HTMLElement>;
  appendedClasses: CloneSafeOptionsType['appendedClasses'];
  isolatedKey: IsolatedKey;
  name: string;
  layoutState: LayoutType;
  responseVehicle: Vehicle;
  aboutToChange: boolean;
  globalVehicleDiscardEnabled?: boolean;
  initCollapsed: boolean;
  isAutoVehicleSelectionDisabled?: boolean;
  useNativeDropdown?: boolean;
  useSearchableDropdown?: boolean;
  onSelectVehicle: () => void;
  openDialog: () => void;
};

export default function VerifyFitmentDropdowns({
  template,
  rootRef,
  appendedClasses,
  isolatedKey,
  name: widgetName,
  layoutState,
  responseVehicle,
  aboutToChange,
  globalVehicleDiscardEnabled,
  initCollapsed,
  isAutoVehicleSelectionDisabled,
  useNativeDropdown,
  useSearchableDropdown,
  onSelectVehicle,
  openDialog,
}: Props) {
  const dispatch = useDispatch();

  const selection = useSelector(
    createFitmentSearchResponseSelectionSelector(isolatedKey),
  ) as FacetValueFull[];
  const isAllRequiredFieldHasSelection = useSelector(
    createAllRequiredFieldsHaveSelectionSelector(isolatedKey),
  ) as boolean;
  const fitmentFields = useSelector(fitmentSearchFieldsSelector);

  const isVehicleSelected = useSelector(isVehicleSelectedSelector);
  const facets = useSelector(createFitmentSearchResponseFacetsSelector(isolatedKey)) as FacetCollection;

  const collapsing = useCollapsing(rootRef, isVehicleSelected, layoutState, initCollapsed, aboutToChange);

  if (!facets.length) {
    return;
  }

  const selectVehicle = () => {
    if (isAllRequiredFieldHasSelection) {
      dispatch(
        vehicleSelected(responseVehicle, {
          isolatedKey,
          vehicleWidgetName: widgetName,
        }),
      );

      fitmentSearchConfig.onVehicleSelected(responseVehicle.filteredFieldMap);
      onSelectVehicle();

      if (!collapsing.collapsed) {
        collapsing.toggleCollapsed();
      }
    }
  };
  const discardVehicle = () => {
    dispatch(isolatedDiscardVehicle(isolatedKey, globalVehicleDiscardEnabled));
    if (isAllRequiredFieldHasSelection) {
      fitmentSearchConfig.onVehicleDiscarded();
    }
  };

  const selects = createSelectRepeater(
    facets.map(({ field, name, facetedValues }, index) => ({
      entries: facetedValues.map((v) => ({
        value: v.value,
        term: v.term,
        payload: v.payload,
        selected: v.isSelected,
      })),
      extraClassName: 'cm_vehicle-widget_select',
      disabled: !facetedValues.length,
      hideNullOption: true,
      field,
      title: name,
      key: field,
      index,
      useNativeDropdown: !useSearchableDropdown && useNativeDropdown,
      isSearchable: useSearchableDropdown,
      onChange: (term) => {
        const facetValue = facetedValues.find((v) => v.term === term);
        if (facetValue) {
          dispatch(
            replaceVehicleValue(facetValue, isolatedKey, fitmentFields, {
              isAutoVehicleSelectionDisabled:
                isAutoVehicleSelectionDisabled ?? fitmentSearchConfig.isAutoVehicleSelectionDisabled,
              vehicleWidgetName: widgetName,
            }),
          );
        }
      },
    })),
  );

  const component = template.call({
    name: widgetName,
    selects,
    vehicleString: responseVehicle.toString(),
    allowToSetVehicle: isAllRequiredFieldHasSelection,
    allowToDiscardVehicle: !!selection.length,
    aboutToChange,
    discardVehicle,
    selectVehicle,
    openDialog,
    fitmentSearchTitle: fitmentSearchConfig.fitmentSearchTitle,
    template: 'active',
    ...collapsing,
  });

  return <SelectContextProvider>{cloneSafe(component, rootRef, { appendedClasses })}</SelectContextProvider>;
}
