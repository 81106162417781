import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import uiConfig from 'Models/uiConfig/uiConfig.js';
import { getKeyFromWidgetName } from 'Addons/fitmentSearch/isolatedKeys.ts';
import { dialogOpened } from 'Core/actions/dialog.ts';
import {
  createFitmentSearchResponseSelectionSelector,
  isNoVehicleSpecificResultsSelector,
  isNonVehicleProductsMessageSelector,
  isVehicleSelectedSelector,
  isVehicleSelectionForcedSelector,
  selectedVehicleSelector,
  createIsServerChangedVehicleSelector,
} from 'Core/selectors/fitmentSearch/index.js';
import VehicleLabel from './vehicleLabel.js';
import VehicleDropdowns from './vehicleDropdowns.tsx';
import { getClassesByLayoutState, useLayoutState } from './useLayoutState.tsx';

const hiddenClass = 'cm_hide';

const templateToComponentMap = {
  active: VehicleDropdowns,
  locked: VehicleLabel,
};

export default function VehicleWidget({
  isAlwaysActive,
  isAlwaysColumnLayout = false,
  columnBreakpoint,
  name,
  ...otherProps
}) {
  const rootRef = useRef();
  const dispatch = useDispatch();

  const selectedVehicle = useSelector(selectedVehicleSelector);
  const isVehicleSelectionForced = useSelector(isVehicleSelectionForcedSelector);
  const isNoVehicleSpecificResults = useSelector(isNoVehicleSpecificResultsSelector);
  const isNonVehicleProductsMessage = useSelector(isNonVehicleProductsMessageSelector);

  const [aboutToChange, setAboutToChange] = useState(false);

  const isolatedKey = getKeyFromWidgetName(name) ?? otherProps.isolatedKey;
  const [nameRoot] = name.split('_');

  const isHidden = useIsHidden(
    nameRoot,
    isolatedKey,
    otherProps.isHidden,
    isNoVehicleSpecificResults,
    isNonVehicleProductsMessage,
  );
  const layoutState = useLayoutState(
    rootRef,
    isolatedKey,
    columnBreakpoint,
    isAlwaysColumnLayout,
    otherProps.fields,
  );
  const templateName = useTemplateName(isAlwaysActive || aboutToChange, isolatedKey);

  useEffect(
    function showLabelWhenVehicleChanges() {
      setAboutToChange(false);
    },
    [selectedVehicle],
  );

  const onChangeVehicle = () => setAboutToChange(true);
  const onSelectVehicle = () => {
    otherProps.onSelectVehicle?.();
    setAboutToChange(false);
  };
  const openDialog = () => dispatch(dialogOpened('vehicle-dialog', { withCurrentVehicle: true }));

  if (templateName === null) {
    return null;
  }

  const Component = templateToComponentMap[templateName];
  const props = {
    ...otherProps,
    name,
    isolatedKey,
    aboutToChange,
    onChangeVehicle,
    onSelectVehicle,
    openDialog,
    rootRef,
    layoutState,
    isVehicleSelectionForced,
    redirectUrl:
      nameRoot === 'HeaderVehicleWidget' && isNonVehicleProductsMessage
        ? uiConfig.searchPageUrl
        : otherProps.redirectUrl,
    appendedClasses: [...getClassesByLayoutState(layoutState), isHidden ? hiddenClass : ''],
    templateName,
  };
  return <Component {...props} />;
}

function useIsHidden(
  nameRoot,
  isolatedKey,
  isHidden,
  isNoVehicleSpecificResults,
  isNonVehicleProductsMessage,
) {
  return useSelector((state) => {
    const isAnyFitmentFacetSelected =
      !!createFitmentSearchResponseSelectionSelector(isolatedKey)(state)?.length;

    return (
      isHidden ||
      (nameRoot !== 'HeaderVehicleWidget' &&
        nameRoot !== 'DialogVehicleWidget' &&
        nameRoot !== 'TabsVehicleWidget' &&
        nameRoot !== 'GarageVehicleWidget' &&
        ((isNoVehicleSpecificResults && !isAnyFitmentFacetSelected) || isNonVehicleProductsMessage))
    );
  });
}

function useTemplateName(forceDropdowns, isolatedKey) {
  return useSelector(
    forceDropdowns
      ? () => 'active'
      : (state) =>
          !isVehicleSelectedSelector(state) || createIsServerChangedVehicleSelector(isolatedKey)(state)
            ? 'active'
            : 'locked',
  );
}
