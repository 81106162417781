import { isolatedDialog, isolatedHeader, isolatedVerifyFitment } from 'Addons/fitmentSearch/isolatedKeys.ts';
import { vehicleLandingFacetField } from 'Addons/fitmentSearch/vehicleLandingFacetField.ts';
import { dialogOpened } from 'Core/actions/dialog.ts';
import {
  clearVehicleDialogRequest,
  clearVerifyFitmentRequest,
  setFitmentSearchResponseFacets,
  setVehicleDialogSelection,
  vehicleSelected,
  sendInitIsolatedRequest,
  garageCleanupRequested,
  vehicleDiscardingRequested,
  setGarageRequested,
  removalFromGarageRequested,
  replaceVehicleValue,
  sendVehicleDialogRequest,
  sendVerifyFitmentRequest,
  forceVehicleSelection,
} from 'Core/actions/fitmentSearch/index.js';
import { replaceInHistory, replaceLocation } from 'Core/actions/redirect.ts';
import { setResponse } from 'Core/actions/response.ts';
import { epicFromHandlers, tryResponseRedirect } from 'Core/epics/common.js';
import {
  createFitmentSearchRequestSelectionSelector,
  selectedVehicleSelector,
  vehicleSelectionSelector,
  createFitmentSearchSelectionChangedSelector as createSelectionChangedSelector,
  vehicleInexactSelector,
  createAllRequiredFieldsHaveSelectionSelector,
  createRequiredFitmentSearchFieldsSelector,
  createFitmentSearchResponseFacetsSelector,
  createResponseVehicleSelector,
  createIsServerChangedVehicleSelector,
  createFitmentSearchExtraFieldsSelector,
  isSpecifyDialogOpenedSelector,
  isVehicleSelectionForcedSelector,
  garageDataSelector,
} from 'Core/selectors/fitmentSearch/index.js';
import { createFilteredResponseSelectionSelector } from 'Core/selectors/search.js';
import fitmentSearchConfig from 'Models/uiConfig/fitmentSearchConfig.js';
import productConfig from 'Models/uiConfig/productConfig.js';
import requestConfig from 'Models/uiConfig/requestConfig.js';
import { getUriFromRequest } from 'Modules/converter/index.js';
import { abortControllerCreator, search, saveGarageToAccount } from 'Modules/serverApi/index.js';

// a temporary variable to change product id for the VerifyRequest requests
let verifyFitmentItemId = productConfig.localItemId;

const createAbortController = abortControllerCreator();

export default epicFromHandlers({
  [clearVehicleDialogRequest]: (arg) => sendIsolatedRequest(arg, isolatedDialog),
  [garageCleanupRequested]: (arg) => {
    sendIsolatedRequestForMultipleKeys(arg);
    onGarageUpdated(arg);
  },
  [vehicleDiscardingRequested]: (arg) => {
    if (arg.action.meta.isInDialog) {
      sendIsolatedRequest(arg, isolatedDialog);
    }

    sendIsolatedRequestForMultipleKeys(arg);

    onGarageUpdated(arg);
  },
  [setGarageRequested]: (arg) => {
    const {
      dispatch,
      action: {
        payload: { vehicles, selectVehicle },
      },
    } = arg;

    if (selectVehicle) {
      dispatch(vehicleSelected(vehicles.last, { isVehicleAutoSelected: true }));
    }

    onGarageUpdated(arg);
  },
  [removalFromGarageRequested]: (arg) => {
    sendIsolatedRequestForMultipleKeys(arg);
    onGarageUpdated(arg);
  },
  [sendVerifyFitmentRequest]: (arg) => {
    const { productId } = arg.action.meta;

    if (productId) {
      verifyFitmentItemId = productId;
    }

    sendIsolatedRequest(arg, isolatedVerifyFitment);
  },
  [clearVerifyFitmentRequest]: (arg) => sendIsolatedRequest(arg, isolatedVerifyFitment),
  [dialogOpened]: (arg) => {
    const {
      state,
      dispatch,
      action: {
        payload,
        meta: { vehicleWidgetIncluded, withCurrentVehicle },
      },
    } = arg;
    if ((payload !== 'vehicle-dialog' && !vehicleWidgetIncluded) || vehicleInexactSelector(state)) {
      return;
    }
    if (withCurrentVehicle) {
      const { selection } = selectedVehicleSelector(state);
      dispatch(setVehicleDialogSelection(selection));
    } else {
      sendIsolatedRequest(arg, isolatedDialog);
    }
  },
  [sendVehicleDialogRequest]: (arg) => sendIsolatedRequest(arg, isolatedDialog),
  [setVehicleDialogSelection]: (arg) => sendIsolatedRequest(arg, isolatedDialog),
  [forceVehicleSelection]: (arg) => sendIsolatedRequest(arg, isolatedDialog),
  [replaceVehicleValue]: handleSelectionChangeIfNeeded,
  [sendInitIsolatedRequest]: (arg) => sendIsolatedRequestForMultipleKeys(arg, true),
  [setFitmentSearchResponseFacets]: tryAutoSelectVehicle,
  [setResponse]: (arg) => {
    syncUrlWithVehicle(arg);
    return tryAutoSelectVehicle(arg); // do return because of the test
  },
  [vehicleSelected]: (arg) => {
    const {
      action: {
        meta: { sendIsolatedRequest },
      },
    } = arg;
    if (sendIsolatedRequest) {
      sendIsolatedRequestForMultipleKeys(arg);
    }

    onGarageUpdated(arg);
  },
  DISCARD: ({ action: { value: { field } = {} } }) => {
    if (field === vehicleLandingFacetField) {
      return vehicleDiscardingRequested({ mayDiscardValue: false });
    }
  },
  RESET_REQUEST: ({ state }) => {
    const selection = createFilteredResponseSelectionSelector()(state);
    const isVehicleLandingFacetWasSelected = selection.some((s) => s.field === vehicleLandingFacetField);

    if (isVehicleLandingFacetWasSelected) {
      return vehicleDiscardingRequested({ mayDiscardValue: false });
    }
  },
});

function sendIsolatedRequestForMultipleKeys(arg, onInit) {
  const {
    state,
    dispatch,
    action: { meta: { extra = {} } = {} },
  } = arg;
  const { isolatedKeys } = fitmentSearchConfig;

  if (!isolatedKeys.length || (isolatedKeys.length === 1 && isolatedKeys[0] === isolatedDialog)) {
    return;
  }

  const preselection =
    fitmentSearchConfig.isOnCategorySelectionPage && isolatedKeys.includes(isolatedHeader)
      ? requestConfig.localPreselection
      : [];
  const vehicleSelection = vehicleSelectionSelector(state);
  const selection = [...preselection, ...vehicleSelection];

  const request = {
    ...withAdditionalParams(
      arg,
      selection,
      isolatedKeys.includes(isolatedVerifyFitment) ? isolatedVerifyFitment : isolatedHeader,
    ),
    extra,
  };

  if (isolatedKeys.includes(isolatedVerifyFitment)) {
    request.filterQuery = verifyFitmentItemId && `${productConfig.fields.id}:${verifyFitmentItemId}`;
  }

  search(request).then((response) => {
    if (tryResponseRedirect(arg, response)) {
      return;
    }

    isolatedKeys.forEach((isolatedKey) => {
      dispatch(setResponse(response, { isolatedKey, onInit }));

      if (isolatedKey === isolatedVerifyFitment) {
        fitmentSearchConfig.onVerifyFitmentInitResponseReceived?.(response);
      }
    });
  });
}

function handleSelectionChangeIfNeeded(arg) {
  const {
    state,
    action: {
      meta: { isolatedKey },
    },
  } = arg;
  if (isolatedKey && createSelectionChangedSelector(isolatedKey)(state)) {
    sendIsolatedRequest(arg, isolatedKey);
  }
}

function sendIsolatedRequest(arg, isolatedKey) {
  const {
    state,
    dispatch,
    action: { meta: { extra = {}, responseMeta = {}, sendIsolatedRequest, vehicle } = {} },
  } = arg;

  const preselection =
    fitmentSearchConfig.isOnCategorySelectionPage && isolatedKey === isolatedHeader
      ? requestConfig.localPreselection
      : [];
  const fitmentSearchSelection = vehicle
    ? vehicle.selection
    : createFitmentSearchRequestSelectionSelector(isolatedKey)(state);
  const selection = [...preselection, ...fitmentSearchSelection];

  const request = {
    ...withAdditionalParams(arg, selection, isolatedKey),
    extra,
  };

  if (isolatedKey === isolatedVerifyFitment) {
    request.filterQuery = verifyFitmentItemId && `${productConfig.fields.id}:${verifyFitmentItemId}`;
  }

  search(request, createAbortController()).then((response) => {
    if (isolatedKey === isolatedVerifyFitment) {
      fitmentSearchConfig.onVerifyFitmentResponseReceived?.(response);

      // handle VerifyFitment redirects - handle them here instead of `redirects.js` and make them delayed
      // this is necessary in order to first select a vehicle and then make a redirect
      // because if after redirection the selected vehicle is the previously selected vehicle
      // and not the vehicle that caused the redirection
      // then we will be redirected back to the product from which we were redirected
      const { Actions: [{ Type, RedirectUrl } = {}] = [] } = response;
      if (Type === 'Redirect' && RedirectUrl && window.location.pathname !== RedirectUrl) {
        setTimeout(dispatch(replaceLocation(RedirectUrl)), 0);
      }
    }

    if (response.State === 'cancelled') {
      return;
    }
    dispatch(
      setResponse(response, {
        isolatedKey,
        sendIsolatedRequest,
        ...responseMeta,
      }),
    );
  });
}

const additionalParamsMap = {
  [isolatedDialog]: {
    // queryParamsFromObject() ignores falsy value of a parameter. have to use '0' for pageSize
    pageSize: '0',
    mode: 'YMM',
  },
  [isolatedHeader]: {
    pageSize: '0',
    mode: 'YMM',
  },
  [isolatedVerifyFitment]: {
    pageSize: 1,
    mode: 'VerifyFitment',
    variantsMode: 'Strip', // do not return item variants in the response
  },
};
function withAdditionalParams({ state }, selection, isolatedKey) {
  const params = additionalParamsMap[isolatedKey];
  const { selection: additionalSelection = [], ...additionalParams } =
    (typeof params === 'function' ? params(state) : params) || {};

  return { ...additionalParams, selection: [...selection, ...additionalSelection] };
}

function syncUrlWithVehicle(arg) {
  // Since facet selection pages don't send search requests and don't use search state,
  // we need to manually add the vehicle to the URL
  // In this particular place we can add a vehicle from response. We can't do it everywhere.
  // Check this PR for more information https://github.com/Convermax/SiteSearch/pull/6827

  if (fitmentSearchConfig.isOnCategorySelectionPage) {
    const responseVehicleSelection = createResponseVehicleSelector(arg.action.meta?.isolatedKey ?? null)(
      arg.state,
    )?.selection;

    const shouldUpdateUrl =
      (!fitmentSearchConfig.hideVehicleFromUrl || fitmentSearchConfig.doNotSaveSelectedVehicle) &&
      responseVehicleSelection?.length;

    if (shouldUpdateUrl) {
      const newLocation = getUriFromRequest(
        { selection: responseVehicleSelection },
        { pathname: window.location.pathname },
      );
      arg.dispatch(replaceInHistory(newLocation, null));
    }
  }
}

function tryAutoSelectVehicle(arg) {
  const {
    state,
    action: {
      meta: {
        isAutoVehicleSelectionDisabled,
        isolatedKey,
        isPartialMode,
        onInit,
        sendIsolatedRequest,
        doNotRedirectOnVehicleSelect,
        redirectUrl,
        vehicleWidgetName,
      } = {},
    },
  } = arg;

  const isSelectedVehicleNew = createIsServerChangedVehicleSelector(isolatedKey)(state);

  if (
    isSelectedVehicleNew &&
    !(isAutoVehicleSelectionDisabled ?? fitmentSearchConfig.isAutoVehicleSelectionDisabled)
  ) {
    const responseFitmentFacets = createFitmentSearchResponseFacetsSelector(isolatedKey)(state);
    const responseFitmentFacetsWithSelection = responseFitmentFacets?.filter((f) => f.selection?.length);

    const responseVehicle = createResponseVehicleSelector(isolatedKey)(state);
    const isSpecifyDialogOpened = isSpecifyDialogOpenedSelector(state);
    const isVehicleSelectionForced = isVehicleSelectionForcedSelector(state);

    if (isPartialMode) {
      const requiredFields = createRequiredFitmentSearchFieldsSelector(isolatedKey)(state);
      const extraFields = createFitmentSearchExtraFieldsSelector(isolatedKey)(state);

      const justChangedField = responseFitmentFacetsWithSelection?.at(
        responseFitmentFacetsWithSelection?.length - 1,
      ).field;

      const isJustChangedExtraField = extraFields && extraFields.includes(justChangedField);

      const allRequiredFieldsHaveSelection = createAllRequiredFieldsHaveSelectionSelector(isolatedKey)(state);

      // do not save the vehicle with only one field in partial mode
      if ((requiredFields?.length > 1 && allRequiredFieldsHaveSelection) || isJustChangedExtraField) {
        fitmentSearchConfig.onVehicleSelected(responseVehicle.filteredFieldMap);

        return vehicleSelected(responseVehicle, {
          isolatedKey,
          isSpecifyDialogOpened,
          isVehicleSelectionForced,
          isVehicleAutoSelected: true,
          onInit,
          sendIsolatedRequest,
          doNotRedirectOnVehicleSelect,
          redirectUrl,
          vehicleWidgetName,
        });
      }
    }

    const allResponseFitmentFacetsHaveSelection =
      responseFitmentFacetsWithSelection?.length === responseFitmentFacets?.length;

    if (allResponseFitmentFacetsHaveSelection) {
      fitmentSearchConfig.onVehicleSelected(responseVehicle.filteredFieldMap);

      return vehicleSelected(responseVehicle, {
        isolatedKey,
        isSpecifyDialogOpened,
        isVehicleSelectionForced,
        isVehicleAutoSelected: true,
        onInit,
        sendIsolatedRequest,
        doNotRedirectOnVehicleSelect,
        redirectUrl,
        vehicleWidgetName,
      });
    }
  }
}

function onGarageUpdated(arg) {
  const { customerId } = window.Convermax;
  if (!customerId) {
    return;
  }

  const garage = garageDataSelector(arg.state).map((vehicle) => vehicle.filteredFieldMap);
  const selectedVehicle = selectedVehicleSelector(arg.state).filteredFieldMap;

  saveGarageToAccount(customerId, garage, selectedVehicle);
}
