import { RefObject, useCallback } from 'react';
import { useSelector } from 'react-redux';

import useResizeState from 'Core/hooks/resizeState.js';
import { createFitmentSearchResponseFacetsSelector } from 'Core/selectors/fitmentSearch';

import type { IsolatedKey } from 'Addons/fitmentSearch/isolatedKeys.ts';
import type { Facet } from 'Models';

const estimatedWidthPerSelect = 150;

export enum LayoutType {
  row = 'row',
  column = 'column',
}

export function useLayoutState(
  rootRef: RefObject<HTMLElement | undefined>,
  isolatedKey: IsolatedKey,
  columnBreakpoint?: number,
  isAlwaysColumnLayout?: boolean,
  fields?: string[],
) {
  const dropdownMaxColumnWidth = useSelector(
    columnBreakpoint
      ? () => columnBreakpoint
      : (state) => {
          const responseFacets = createFitmentSearchResponseFacetsSelector(isolatedKey)(state);
          const selectCount = (
            fields ? responseFacets.filter((facet: Facet) => fields.includes(facet.field)) : responseFacets
          ).length;
          return selectCount * estimatedWidthPerSelect;
        },
  );

  return useResizeState(
    useCallback(
      (state = isAlwaysColumnLayout ? LayoutType.column : LayoutType.row) =>
        isAlwaysColumnLayout
          ? LayoutType.column
          : rootRef.current?.offsetParent
            ? rootRef.current.clientWidth > dropdownMaxColumnWidth
              ? LayoutType.row
              : LayoutType.column
            : state,
      [dropdownMaxColumnWidth, isAlwaysColumnLayout, rootRef],
    ),
  );
}

export const getClassesByLayoutState = (layoutState: LayoutType) => {
  return layoutState === LayoutType.row
    ? ['cm_row', 'cm_vehicle-widget__row']
    : layoutState === LayoutType.column
      ? ['cm_column', 'cm_vehicle-widget__column']
      : [];
};
